<template>
  <el-dialog
    v-model="HistoryEarthquakeVisible"
    title="历史地震情况"
    width="50%"
  >
    <div class="el-dialog-div">
      <el-descriptions
        v-for="(item) in currentPageEarthquake"
        :key="item"
        :title="item.earthquakeName"
        :column="3"
        border
      >
        <template #extra />
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-info" />
            地震名称
          </template>
          {{ item.earthquakeName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-s-data" />
            震级
          </template>
          {{ item.magnitude }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-location-outline" />
            震源经纬度
          </template>
          ({{ item.longitude }},{{ item.latitude }})
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-tickets" />
            最高烈度
          </template>
          {{ item.highIntensity }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-timer" />
            地震发生时间
          </template>
          {{ item.earthquakeTime }}
        </el-descriptions-item>
      </el-descriptions>
      <el-pagination
        v-model:current-page="currentPage"
        layout="prev, pager, next, jumper, ->,  total"
        :total="HistoryEarthquakeList.length"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getHistoryEarthquakeInfoList } from "@/axios/api/OthersApi";
export default {
  name: "HistoryEarthquake",
    // eslint-disable-next-line vue/require-prop-types
  props:['earthquake'],
  data(){
    return {
      currentPage: 1,
      HistoryEarthquakeVisible:false,
      HistoryEarthquakeList:[]
    }
  },
  computed:{
    currentPageEarthquake(){
      return this.HistoryEarthquakeList.slice((this.currentPage-1)*10,this.currentPage*10)
    },
  },
  created() {
    // this.getHistoryEarthquake()
  },
  methods:{
    creatHistoryEarthquake(){
      this.HistoryEarthquakeVisible=true
          this.getHistoryEarthquake()
    },
    getHistoryEarthquake(){
      // console.log(this.earthquake[0])
      let that=this;
      getHistoryEarthquakeInfoList(this.earthquake[0]['value'])
      // this.$axios.get('earthquakeInfo/getHistoryList?name='+this.earthquake[0]['value'])
          .then(function (res){
            that.HistoryEarthquakeList=res.data
            // console.log(res)
            })
    }
  }
}
</script>

<style scoped>
.el-dialog-div{
  height: 50vh;
  overflow: auto;
  padding: 10px;
}
</style>