<template>
  <!--  <el-button type="primary" icon="el-icon-search" round @click="earthquakeSelectVisible=true" class="toolbar-item" style="margin: 5px">地震列表</el-button>-->
  <el-dialog
    v-model="earthquakeSelectVisible"
    title="选择地震"
    width="50%"
  >
    <div>
      <el-input
        v-model="searchEarthquake"
        placeholder="输入地震名称搜索"
      />
    </div>

    <div class="el-dialog-div">
      <el-collapse>
        <el-collapse-item title="条件筛选">
          <el-form>
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="timeRange"
                :shortcuts="shortcuts"
                type="datetimerange"
                range-separator="到"
                start-placeholder="最早日期"
                end-placeholder="最晚日期"
              />
            </el-form-item>
            <el-form-item label="限制区域">
              <el-select
                v-model="area"
                placeholder="Select"
              >
                <el-option
                  v-for="item in areas"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="最低震级">
              <el-select
                v-model="low"
                szie="small"
                placeholder="0"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="最高震级">
              <el-select
                v-model="high"
                placeholder="10"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <div style="float: right">
              <el-button
                round
                type="primary"
                @click="selectEarthquakeByCondition"
              >
                筛选
              </el-button>
              <el-button
                round
                type="success"
                @click="selectEarthquakeByCondition"
              >
                显示全部
              </el-button>
            </div>
          </el-form>
          <br>
        </el-collapse-item>
      </el-collapse>
      <el-descriptions
        v-for="(item,index) in currentPageEarthquake"
        :key="item"
        :title="item.earthquakeName"
        :column="3"
        border
      >
        <template #extra>
          <el-button
            v-if="item.earthquakeId!==selectedEarthquakeId"
            type="primary"
            size="small"
            @click="$emit('changeSelect',earthquakeInfoList[index].earthquakeId);earthquakeSelectVisible=false"
          >
            选择
          </el-button>
          <el-button
            v-if="item.earthquakeId===selectedEarthquakeId"
            type="info"
            size="small"
          >
            已选择
          </el-button>
          <el-button
            type="success"
            size="small"
            @click="$emit('jumpTo',item.longitude,item.latitude,100000);earthquakeSelectVisible=false"
          >
            跳转
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click="deleteEarthquake(item.earthquakeId)"
          >
            删除
          </el-button>
        </template>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-info" />
            地震名称
          </template>
          {{ item.earthquakeName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-s-data" />
            震级
          </template>
          {{ item.magnitude }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-location-outline" />
            震源经纬度
          </template>
          ({{ item.longitude }},{{ item.latitude }})
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-tickets" />
            最高烈度
          </template>
          {{ item.highIntensity }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-timer" />
            地震发生时间
          </template>
          {{ item.earthquakeTime }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- @current-change="getNextPage" -->
      <el-pagination
        layout="prev, pager, next, jumper, ->,  total" 
        :current-page="table.currentPage" 
        :page-size="table.pageSize"
        :total="earthquakeInfoList.length+1"
        @size-change="handlePageChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getNextPageApi,getEarthquakeInfoByCondition,getEarthquakePositionInfoByCondition} from "@/axios/api/OthersApi";
import {changeGeoJsonToPosition} from "@/js/SeismicZones";
export default {
  name: "EarthquakeSelect",
    // eslint-disable-next-line vue/require-prop-types
  props: ['selectedEarthquakeId'],
  emits: ['changeSelect','updateList','jumpTo'],
  data() {
    return {
      currentPageEarthquake:[],
      config:{
        'min':4,
        'page':1
      },
      table:
      {
      'total':0,
      'pageSize':10,
      'currentPage': 1,},
      earthquakeSelectVisible:false,
      searchEarthquake: "",
      area: "china",
      areas: [
        {
          value: "中旬大理地震带",
          label: "中旬大理地震带"
        },
        {
          value:"china",
          label:"中国"
        }
      ],
      timeRange:[
        new Date(2000, 10, 10, 10, 10),
        new Date(),
      ],
      shortcuts:[
        {
          text: '最近一天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            return [start, end]
          }
        },
        {
          text: '最近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          }
        },
        {
          text: '最近一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          }
        },
        {
          text: '最近3个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          }
        },
        {
          text: '最近半年',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            return [start, end]
          }
        },
        {
          text: '最近一年',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            return [start, end]
          }
        }
      ],
      low:0,
      high:10,
      options:[
        {value:0, label:0},{value:1, label:1},{value:2, label:2},
        {value:3, label:3},{value:4, label:4},{value:5, label:5},
        {value:6, label:6},{value:7, label:7},{value:8, label:8},
        {value:9, label:9},{value:10, label:10},
       ]
    }
  },
  created(){
    const that=this
    getNextPageApi(1).then(res=>{that.earthquakeInfoList=res ;that.updtaeCurrentPageEarthquake()})
  },
  methods:{
    deleteEarthquake(){
      this.$message.error('权限不足')
    },
    //监听翻页
    handleCurrentChange(page){
      console.log(this.earthquakeInfoList);
      const that=this
      if(page>that.earthquakeInfoList.length/10)
      {this.config['page']=page;
        getEarthquakeInfoByCondition(this.config).then(response=>{ 
        for(let i=0;i<response.length;i++)
        that.earthquakeInfoList.push(response[i]);
        that.table.currentPage=page
        that.updtaeCurrentPageEarthquake()
      })
    }
      else {
        that.table.currentPage=page
        that.updtaeCurrentPageEarthquake()}
    },
    handlePageChange(new2){
     console.log(new2);
    },
    //更新当前页面的地震数据
    updtaeCurrentPageEarthquake(){
      // console.log(this.earthquakeInfoList);
      let result=[]
      if(this.earthquakeInfoList.length===0) this.currentPageEarthquake=[]
      if(this.earthquakeInfoList.length>=this.table.pageSize*this.table.currentPage)
      {
        for(let i=(this.table.currentPage-1)*this.table.pageSize;i<(this.table.currentPage)*this.table.pageSize;i++){
        result.push(this.earthquakeInfoList[i])
      }
      }
      else {
        for(let i=(this.table.currentPage-1)*this.table.pageSize;i<this.earthquakeInfoList.length;i++){
        result.push(this.earthquakeInfoList[i])
      }
      }
      this.currentPageEarthquake=result
    },
    //条件筛选数据
    selectEarthquakeByCondition(){
        console.log(this.searchEarthquake.trim())
        this.config={}
        if(this.area==='中旬大理地震带') {
            const position = changeGeoJsonToPosition()
            this.config['east']=position['east']
            this.config['west']=position['west']
            this.config['north']=position['north']
            this.config['south']=position['south']
        }
       let that=this;
        if(this.searchEarthquake.trim()!=='')
        this.config['name']=this.searchEarthquake.trim()
        this.config['start']=this.dateFtt("yyyy-MM-dd hh:mm:ss",that.timeRange[0])
        this.config['end']=this.dateFtt("yyyy-MM-dd hh:mm:ss",that.timeRange[1])
        this.config['min']=this.low
        this.config['max']=this.high
        this.config['page']=1
      getEarthquakeInfoByCondition(this.config).then(response=>{
        // console.log(response);
        that.earthquakeInfoList=[]
        that.earthquakeInfoList=response
        that.table.currentPage=1
        that.updtaeCurrentPageEarthquake()
        })
      getEarthquakePositionInfoByCondition(this.config).then(response=>{
        that.$emit('updateList',response)})
    },
    judgeSelected(index){
      const realIndex=(this.table.currentPage-1)*this.table.pageSize+index
       return earthquakeInfoList[realIndex].earthquakeId === this.selectedEarthquakeId;

    },
    //格式化时间
    dateFtt(fmt,date) {
      //author: meizz
      let o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
      };
      if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
      for(let k in o)
        if(new RegExp("("+ k +")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      return fmt;
    }
  }
}
</script>

<style scoped>
.el-dialog-div{
  height: 50vh;
  overflow: auto;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #6FB0FC;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
</style>
