
import {http}  from "../httpConfig";
// import { ElMessage } from "element-plus"
//获取全部地震位置信息
function getAllEarthquakePositionInfo() {
    return http.get('/earthquakeInfo/getAllEarthquakePositionInfo','').then(response=>{
      return response.data})
}
//初始化地图数据
function initEarthquakePositionInfo(params) {
  return http.post('/earthquakeInfo/getEarthquakePositionInfoByCondition',{'max':10,'min':params}).then(response=>{
    return response.data})
}
//地震id获取地震详细信息
function getEarthquakeInfoById(params) {
  return http.get('/earthquakeInfo/getEarthquakeInfoById',{'earthquakeId':params}).then(response=>{
    return response.data
  })
}
//地震id获取地震详细信息(详细框用)
function getDetailEarthquakeInfoById(params) {
    return http.get('/earthquakeInfo/getEarthquakeInfoById',{'earthquakeId':params}).then(response=>{
        const earthquakeInfo=response.data
        const detailInfo=[
        // {
        //   key:'地震编号',
        //   value:earthquakeInfo.earthquakeId
        // },
        {
          key:'地震名称',
          value: earthquakeInfo.earthquakeName,
        },
        {
          key:'震级',
          value: earthquakeInfo.magnitude,
        },
        {
          key:'震源经纬度',
          value: earthquakeInfo.longitude+','+earthquakeInfo.latitude,
        },
        {
          key:'震中烈度',
          value: earthquakeInfo.highIntensity,
        },
        {
          key:'发生时间',
          value: earthquakeInfo.earthquakeTime,
        },]
        return detailInfo
    })
}
//地震Id获取附近医院位置
function getAllNearHospitalPositionByEarthquakeId(params) {
    return http.get('/getAllNearHospitalPositionByEarthquakeId',params)
}
//医院id查医院详细信息
function getHospitalByHospitalId(params) {
    return http.get('/getHospitalByHospitalId',params).then(response=>{
        let hospital=response.data
        const detailInfo= [
         {
           key:'医院名称',
           value: hospital.name,
         },
         {
           key:'地址',
           value: hospital.address,
         },
         {
           key:'经纬度',
           value: hospital.lon+','+hospital.lat,
         },
         {
           key:'所在省市',
           value: hospital.pname+hospital.cityname,
         },
         {
           key:'类型',
           value: hospital.type,
         }]
         return detailInfo
        })
}
//地震Id获取附近医院信息
function getAllNearHospitalByEarthquakeId(params) {
    return http.get('/getAllNearHospitalByEarthquakeId',params)
}
//地震Id获取附近消防站信息
function getAllNearFireCenterByEarthquakeId(params) {
    return http.get('/getAllNearFireCenterByEarthquakeId',params)
}
//地震Id获取附近消防站位置信息
function getAllNearFireCenterPositionByEarthquakeId(params) {
    return http.get('/getAllNearFireCenterPositionByEarthquakeId',params)
}
//物资分配
function getNearFireCenterWeightByEarthquakeId(id,count) {
    return http.get('/getNearFireCenterWeightByEarthquakeId',{'earthquakeId':id,'fireCenterCount':count}).then(response=>{
      return Promise.resolve(response)
    })
}
//路径规划
function getNearestHospitalByPosition(lon,lat) {
      return  http.get('/getNearestHospitalByPosition',{'longitude':lon,'latitude':lat})
}
//高德地图路径规划,params为交通方式，起始位置
function restapi(params) {
     return http.axios({
        method:'get',
        url:"https://restapi.amap.com"+params.travelWay, 
        params: {
          output: "json",
          extensions: "all",
          key: "e21feddaeef263e2506376a2ddbb994e", // https://lbs.amap.com/api/webservice/guide/api/direction
          origin: params.startP[0] + "," + params.startP[1],
          destination: params.endP[0] + "," + params.endP[1],
          strategy: 11 || 10,
        },
      })
}
export {
    getAllEarthquakePositionInfo,
    initEarthquakePositionInfo,
    getEarthquakeInfoById,
    getAllNearHospitalPositionByEarthquakeId,
    getHospitalByHospitalId,
    getAllNearHospitalByEarthquakeId,
    getAllNearFireCenterByEarthquakeId,
    getAllNearFireCenterPositionByEarthquakeId,
    getNearFireCenterWeightByEarthquakeId,
    getNearestHospitalByPosition,
    getDetailEarthquakeInfoById,
    restapi
}